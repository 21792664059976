<template>
	<div class="Depart">
		<Row class="px-1 pb-1 bg-white">
			<i-col span="8" class="align-justify">
				<div class="departTree" :style="{height:treheight}">
						<Tree show-checkbox multiple :data="treeData"></Tree>
				</div>
				<Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
				<Button type="default" icon="md-add" size="large" class="mr-1"></Button>
				<Button type="default" icon="md-trash" size="large" class="mr-1"></Button>
				<Button type="default" icon="md-arrow-up" size="large" class="mr-1"></Button>
				<Button type="default" icon="md-arrow-down" size="large"></Button>
			</i-col>
			<i-col span="16">

			</i-col>
		</Row>
	</div>
</template>

<script>
	import treeData from '../json/depart_trData.json'
	export default {
		name: 'Depart',
		data() {
			return {
				treeData,
				organ:{},
				treheight: (window.innerHeight - 233)/16 + 'rem'
			}
		},
		created() {
			this.organ = JSON.parse(sessionStorage.getItem('pageStr'));
			this.crumbstring([this.organ.unitName])
		},
		mounted() {
			window.addEventListener('resize', () => this.treheight = (window.innerHeight - 233)/16 + 'rem', false);
		},
		updated() {

		},
		methods: {
			crumbstring(arr) {
				let setValue = {
					lenCtrl: 3,
					addValue: arr
				};
				if(this.organ.unitId){
					this.$store.dispatch('setBreadcrumb', setValue)
				}else{
					this.$router.push({name: 'Organ'})
				}
			}
		}

	}
</script>

<style lang="less" scoped="scoped">
	.departTree {
		border: solid 1px #999;
		margin-bottom: 0.5rem;
		padding: 0 0.5rem;
	}
</style>
